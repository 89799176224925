@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,400;0,500;0,600;0,700;1,200;1,400;1,500;1,600;1,700&display=swap");

body {
  background-color: #0f172a;
  /* background: linear-gradient(to bottom, #0f172a, #1f2e41); */
  font-family: "Poppins", sans-serif;
  /* font-family: "Rubik", sans-serif; */
  /* font-family: "Montserrat", sans-serif; */
}

.custom-cursor {
  position: fixed;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: rgba(255, 0, 0, 0.5); /* Red color with 50% opacity */
  pointer-events: none; /* Ensure the custom cursor doesn't interfere with mouse interactions */
  z-index: 9999; /* Set a high z-index to make sure it's above other elements */
}
