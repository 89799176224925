/* CustomCursor.css */
.custom-cursor {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 1000px;
  height: 1000px;
  border-radius: 50%;
  background: radial-gradient(
      circle,
      rgba(0, 166, 255, 0.147),
      rgba(0, 166, 255, 0.078),
      rgba(15, 23, 42, 0.5),
      rgba(15, 23, 42, 0.1)
    ),
    linear-gradient(#0f172a, transparent); /* Add the linear gradient */
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: -1; /* Set a lower z-index to make the cursor appear behind everything else */
}

body {
  position: relative;
  z-index: -1;
}
